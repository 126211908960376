import { useReactiveVar } from '@apollo/client';
import { previewEnabledVar, previewCanCommentVar, previewUsersVar } from '@/graphql/cache';

/**
 * @returns {{preview: boolean, previewUsers: object[], previewCanComment: boolean}}
 */
export default function usePreview() {
  const preview = useReactiveVar(previewEnabledVar);
  const previewCanComment = useReactiveVar(previewCanCommentVar);
  const previewUsers = useReactiveVar(previewUsersVar);

  return {
    preview,
    previewCanComment: preview ? previewCanComment : true,
    previewUsers,
  };
}
